@import '../../components/glassTheme/Common.scss';

.resultMessage {
  width: 100%;
  font-size: small;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.8);;
  border: none;
  height: 400px;
  overflow: scroll -moz-scrollbars-none;
  -ms-overflow-style: none;
  resize: none;
  background-color: transparent;
  border-radius: 38px;
  padding: 15px;
}
.resultMessage::-webkit-scrollbar {
    width: 0 !important
}

.fileControls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.apiSampleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  min-width: 400px;
  max-width: 700px;
}

.apiEndpointsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 25px;
  min-width: 400px;
  max-width: 700px;
}

.apiEndpoint {
  padding: 3px 3px 3px 3px;
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.apiEndpointHeader {
  text-align: left;
  font-size: small;
  line-height: 50px;
  font-weight: 600;
  color: white;
  overflow: ellipsis;
  resize: none;
  padding-left: 10px;
}

.radioBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  padding: 5px;

  label {
    @extend .tLeft;
  }
}

.standingsControls-inputBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  gap: 5px;

  label {
    @extend .tLeft;
  }
}

.actionContainer {
  min-width: 200px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.uploadResultContainer {
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.tabButton {
  @extend .glass-button;
  margin-top: 15px;
  padding: 0px 18px;
  background-color: rgba(0, 0, 0, 0.2);
  height: 30px;
  transition: 500ms linear;
  margin: 5px;
  outline: none;
}
.tabButton:hover {
  scale: 1.1;
  outline: none;
}

.tabSelected {
  @extend .tabButton;
  background-color: rgba(0, 0, 0, 0.7);
  transition: 500ms linear;
}

.tabSelected:hover {
  scale: 1.1;
}

.actionButtonContainer {
  @extend .unselectable;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  bottom: 12px;
  right: 14px;
  position: absolute;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.smallIcon {
  color: white;
  margin-top: 2px;
  margin-right: 12px;
  margin-left: 15px;
}

.actionButton {
  border-width: 5px;
  width: 100%;
  height: 100%;
  color: white;
  transition: transform 0.2s ease-in-out;
}

.actionButtonWrapper {
  min-height: 50px;
  min-width: 50px;
  border: 12px solid;
  border-radius: 50px;
  height: 50px;
  width: 50px;
}

.actionButtonWrapper:hover .actionButton {
  color: black;
  transition: color 200ms linear;
  transform: scale(1.2);
}

.actionButtonWrapper:hover {
  background-color: white;
  border-color: white;
  transition: background-color border-color 1000ms linear;
}

.loaderOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.radioForm {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  text-align: center;
  gap: 10px;
}

.standingsForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px 0px 0px 0px;
  width: 100%;
  gap: 20px;
}

.standingsControls {
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: space-evenly;
  gap: 40px;
}

.standingsControls-columnLeft {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-items: start;
  text-align: left;
  gap: 10px;
}

.standingsControls-columnRight {
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-items: start;
  text-align: left;
  gap: 10px;
}

.numberSelect {
  width: 40px;
  align-self: right;
}

.resultContainer {
  display: flex;
  flex-direction: column;
  margin: 0 0 1vmin 0;
  position: relative;
}

.resultContainerTabs {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  align-items: start;
  min-width: 400px;
  max-width: 700px;
  margin-bottom: -5px;
}

.uploadElements {
  @extend .unselectable;
  width: 100%;
  height: 100%;
  box-sizing:border-box;
  text-align:center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.apiSampleElements {
  width: 100%;
  height: 100%;
  box-sizing:border-box;
  text-align:center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  gap: 10px;
}

.uploader-button {
  @extend .glass-button-icon;
  width: 150px;
}

.tdf-export-button {
  @extend .glass-button-icon;
  width: 150px;
}

.eventStateBanner {
  @extend .dark-glass-card;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  max-width: 400px;
  gap: 10px;
}

.eventStateBannerIcon {
  @extend .glass-element-bold;
  width: 30px;
  height: 30px;
}

.eventStateBannerData {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.file-control-header {
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: center;
  gap: 5px;
  width: 100%;
}

.fusion-upload-footer {
  @extend .card-footer-unbound;
  width: 300px;
}

.uploader-controls-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  width: 100%;
  gap: 5px;
}

.standings-select {
  @extend .glass-select;
  width: 100%;
}