
@import '../../components/glassTheme/Common.scss';

.embeddedTimer {
    width: 560px;
    height: 170px;
    overflow: hidden;
    position: relative;
    border: 5px solid black;
    border-radius: 35px;
}