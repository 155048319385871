@import './Colours.scss';

// Material templates
.diffuse {
    // box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
}
.glass {
    background: rgba(255, 255, 255, 0.32);
}
.raised-glass {
    background: rgba(100, 100, 100, 0.2);
}
.overlay-glass {
    background: rgba(50, 50, 50, 0.4);
}
.frost-glass {
    background: rgba(255, 255, 255, 0.6);
}
.hovered-glass {
    background: rgba(255, 255, 255, 0.42);
    box-shadow: 0 4px 50px rgba(0, 0, 0, 0.3);
}
.dark-glass {
    background: rgba(0, 0, 0, 0.6);
}
.darker-glass {
    background: rgba(0, 0, 0, 0.7);
}
.darkest-glass {
    background: rgba(0, 0, 0, 0.9);
}
.dim-glass {
    background: rgba(100, 100, 100, 0.35);
}
.recessed-glass {
    box-shadow: rgba(0, 0, 0, 0.15) 1px 2px 4px 2px inset;
    background: rgba(180, 180, 180, 0.2);
}

// Materials for overlaying glass
.glass-element-bold {
    color: rgba(255, 255, 255);
}
.glass-element-standard {
    color: rgba(235, 235, 235);
}
.glass-element-subtle {
    color: rgba(230, 230, 230);
}
.glass-element-thin {
    color: rgba(180, 180, 180);
}
.glass-bgelement-subtle {
    background: rgba(255, 255, 255, 0.4);
}
.glass-bgelement-thin {
    background: rgba(255, 255, 255, 0.2);
}
.glass-bgelement-blend {
    background: rgba(180, 180, 180, 0.2);
}
.glass-bgelement-dark {
    background: rgba(80, 80, 80, 0.2);
}

// Floating card shapes
.base-card {
    border-radius: 38px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}
.glass-card {
    @extend .base-card;
    @extend .glass;
    @extend .diffuse;
}
.dark-glass-card {
    @extend .base-card;
    @extend .dark-glass;
    @extend .diffuse;
}
.dim-glass-card {
    @extend .base-card;
    @extend .dim-glass;
    @extend .diffuse;
}
.raised-glass-card {
    @extend .base-card;
    @extend .raised-glass;
    @extend .diffuse;
}

.card-footer-base {
    @extend .overlay-glass;
    @extend .diffuse;
    position: relative;
    z-index: 100;
    border-radius: 25px;
    min-height: 50px;
    max-height: 400px;
    width: 300px;
    border: 0.1px solid grey;
    margin-top: -55px;
    transition: height 0.3s ease-in-out;
    overflow: hidden;
    justify-self: center;
}

.card-footer-left {
    @extend .card-footer-base;
    transform: translate(40%, 0%);
}
.card-footer {
    @extend .card-footer-base;
    align-self: center;
}
.card-footer-right {
    @extend .card-footer-base;
    transform: translate(-50%, 0%);
    align-self: end;
    right: 5%;
}
.card-footer-unbound {
    @extend .card-footer-base;
    width: 100%;
}
// Card components
.cardWrapper {
    padding: 30px;
    align-self: center;
    align-items: center;
    display: flex;
    border-radius: 10px;
    width: 95%;
    min-width: 400px;
    max-width: 700px;
    position: relative;
}
.cardInsertWithFooter {
    @extend .cardInsert;
    margin-bottom: 10px;
}
.cardInsert {
    padding: 30px 30px 30px 30px;
    align-self: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
}

// Bubble shapes
.base-bubble {
    border-radius: 50px;
    width: 100%;
    height: 100%;
}
.glass-bubble {
    @extend .glass;
    @extend .base-bubble;
}
.dark-glass-bubble {
    @extend .dark-glass;
    @extend .base-bubble;
}
.dim-glass-bubble {
    @extend .dim-glass;
    @extend .base-bubble;
}
.raised-glass-bubble {
    @extend .raised-glass;
    @extend .base-bubble;
}
.tLeft {
    text-align: left;
}
// Text styles
.base-font {
    font-family: Helvetica, sans-serif, Arial;
}
.glass-title {
    @extend .base-font;
    @extend .glass-element-standard;
    font-size: x-large;
    font-weight: 500;
}
.glass-h1 {
    @extend .base-font;
    @extend .glass-element-standard;
    font-size: large;
    font-weight: 400;
}
.glass-h2 {
    @extend .base-font;
    @extend .glass-element-standard;
    font-size: large;
    font-weight: 400;
}
.glass-h3 {
    @extend .base-font;
    @extend .glass-element-subtle;
    font-size: medium;
    font-weight: 325;
}
.glass-h4 {
    @extend .base-font;
    @extend .glass-element-subtle;
    font-size: small;
    font-weight: 400;
}
.glass-body {
    @extend .base-font;
    @extend .glass-element-subtle;
    font-size: small;
    font-weight: 300;
}
.glass-caption {
    @extend .base-font;
    @extend .glass-element-subtle;
    font-size: x-small;
    font-weight: 200;
}
.glass-tiny {
    @extend .base-font;
    @extend .glass-element-subtle;
    font-size: xx-small;
    font-weight: 200;
}
.glass-boring {
    @extend .glass-tiny;
    @extend .italic;
    @extend .glass-element-thin;
}
.italic {
    font-style: italic;
}
.bold {
    font-weight: 600;
}

// Interactive subviews
.recessed-glass-component {
    @extend .recessed-glass;
    font-size: small;
    font-weight: 300;
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.2);
    overflow: scroll -moz-scrollbars-none;
    -ms-overflow-style: none;
    text-align: center;
    padding: 10px;
    border-radius: 10px;
}
.glass-input {
    @extend .recessed-glass-component;
    height: 30px;
    min-height: 30px;
    max-height: 30px;
}
.glass-input::placeholder {
    color: rgba(250, 250, 250, 0.7);;
}
.glass-input::-webkit-resizer {
    display: none;
}
.glass-input::-webkit-scrollbar {
    width: 0 !important
}
.glass-textarea {
    @extend .recessed-glass-component; 
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    min-height: 50px;
    height: 75px;
    max-height: 300px;
}
.glass-textarea::placeholder {
    color: rgba(250, 250, 250, 0.7);;
}
.glass-textarea::-webkit-resizer {
    display: none;
}
.glass-textarea::-webkit-scrollbar {
    width: 0 !important
}

// Buttons and icons
.text-button {
    @extend .glass-body;
    @extend .tLeft;
    overflow: visible;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

.glass-button-icon-left {
    width: 0px;
    transform: translate(-5px, 2px);
    transition: width 0.3s ease-in-out;
}
.glass-button-icon-right {
    width: 0px;
    transform: translate(5px, 2px);
    transition: width padding 0.3s ease-in-out;
}
.glass-button {
    @extend .base-font;
    @extend .glass-element-bold;
    @extend .glass-bgelement-thin;
    outline: 1px solid rgba(255, 255, 255, 0.4);
    transition: transform 1s ease-in-out;
    border-radius: 100px;
    border: none;
    height: 30px;
    padding: 0 5px;
    overflow: visible;
    align-content: center;
}
.glass-button-icon {
    @extend .glass-button;
}
.glass-button:hover {
color: white;
background-color: rgba(0, 0, 0, 0.6);
transition: color background-color 1000ms linear;
scale: 1.1;
outline: 1px solid rgba(100, 100, 100, 0.4);
}
.glass-button-icon:hover .glass-button-icon-left {
color: white;
width: 15px;
transition: color 500ms linear;
transition: width 0.3s ease-in-out;
}
.glass-button-icon:hover .glass-button-icon-right {
color: white;
width: 15px;
transition: color 500ms linear;
transition: width 0.3s ease-in-out;
}
.glass-button:disabled {
    @extend .dim-glass;
    cursor: not-allowed;
}
.glass-button-mini {
    @extend .glass-button;
    height: 20px;
    padding: 0 5px;
}
// File select
.glass-fileSelect {
    @extend .glass-body;
}
.glass-fileSelect::file-selector-button {
    @extend .glass-button;
    @extend .base-font;
    @extend .glass-element-bold;
    transition: transform 1s ease-in-out;
    padding: 3px 20px;
    margin: 3px;
}
.glass-fileSelect:hover::file-selector-button {
    background-color: rgba(0, 0, 0, 0.6);
    transition: color background-color 1000ms linear;
}

.glass-checkbox {
    @extend .glass-button;
    @extend .base-font;
    @extend .glass-element-bold;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.2);
    border: 2px solid rgba(255, 255, 255, 0.4);
    cursor: pointer;
    display: inline-block;
    position: relative;
}

.glass-checkbox:checked {
    background-color: $sentiment-positive;
    border: 2px solid $interactive-positive;
}

.glass-checkbox:checked::after {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.glass-select {
    @extend .base-font;
    @extend .glass-element-bold;
    @extend .glass-bgelement-blend;
    border-radius: 5px;
    outline: 1px solid rgba(255, 255, 255, 0.4);
    border: none;
    height: 30px;
    overflow: hidden;
    align-content: center;
    width: 100%;
}
.glass-select option {
    background-color: rgba(0, 0, 0, 0.6);
}

.dark-glass-select {
    @extend .glass-select;
    @extend .glass-bgelement-dark;
    outline: 1px solid rgba(120, 120, 120, 0.4);
}

.badge {
    @extend .glass-caption;
    @extend .bold;
    background-color: rgba(255, 255, 255, 0.32);
    padding: 2px 6px;
    text-align: center;
    border-radius: 5px;
}
.shadowless {
    box-shadow: none;
}
.unselectable {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.glass-divider {
    @extend .glass-bgelement-subtle;
    min-height: 1px;
    margin: 2px;
    width: 100%;
}

.glass-divider-vertical {
    @extend .glass-bgelement-subtle;
    min-width: 1px;
    margin: 2px;
}

.data-pair {
    @extend .tLeft;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 0px;
}

.sync-badge {
    width: 26px;
    height: 26px;
    fill: gray;
    position: absolute;
    bottom: -5px;
    left: -10px;
    background-color: orange;
    border-radius: 50%;
}

.sync-badge-icon {
    width: 20px;
    height: 20px;
    fill: white;
    position: absolute;
    padding: 3px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.toggleBox {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    padding: 5px;
}

// Core
.rotate-180 {
    transform: rotate(180deg);
}

.debug {
    color: limegreen;
}
.info {
    color: white;
}
.warning {
    color: lightcoral;
}
.error {
    color: red;
}
.outline {
    outline: 1px solid white;
}

.adminControls {
    position: fixed;
    bottom: 50px;
    left: 50px;
    display: flex;
    flex-direction: row;
    z-index: 100;
    gap: 10px;
}

.filterControls {
    position: fixed;
    bottom: 50px;
    right: 50px;
    display: flex;
    flex-direction: row;
    z-index: 100;
    gap: 10px;
}