@import '../../components/glassTheme/Common.scss';

.pairings-content {
    margin:0;
    box-sizing:border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-bottom: 100px;
}

.timerContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    position: fixed;
    bottom: 20px;
    left: 20px;
}