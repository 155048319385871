* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.app {
  width: 100%;
  height: 100%;
}

.app-bgroot {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  z-index: -2;
}

.app-header {
  z-index: 200;
  position: fixed;
}

.app-content {
  width: 100%;
  height: 100%;
  padding-top: 120px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
}