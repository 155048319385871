
@import '../../../components/glassTheme/Common.scss';

.matchCompact-card {
  width: 95%;
  max-width: 1080px;
  height: 50px;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.matchCompact {
  @extend .diffuse;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 0 10px;
}

.matchCompactControls {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 5;
  transition: all 0.5s ease;
  padding: 0 10px;
}

.matchCompact-separator {
  @extend .dark-glass;
  width: 70px;
  height: 40px;
  border-radius: 25px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  transition: all 0.5s ease;
}

.matchCompact-separator:hover {
  scale: 1.5;
  outline: 3px solid white;
  transition: all 0.5s ease;
}

.matchCompact-tableNumber {
  @extend .glass-h1;
  @extend .bold;
  @extend .italic;
  letter-spacing: 1.2px;
}

.matchCompact-outcome {
  @extend .glass-h3;
  @extend .bold;
  @extend .dark-glass;
  border-radius: 30px;
  padding: 5px 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.matchCompact-playerDetails {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  position: relative;
}

.matchCompact-playerName {
  @extend .glass-h2;
  @extend .bold;
  letter-spacing: 1.2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 70%;
  text-align: left;
}
.matchCompact-playerName-right {
  @extend .matchCompact-playerName;
  text-align: right;
}
.matchCompact-favouriteIcon {
  color: gold;
  width: 20px;
  height: 20px;
  margin: -10px;
  animation: sparkle 1.5s infinite;
  transform: rotate(15deg);
}

@keyframes sparkle {
  0%, 100% {
    filter: brightness(1);
  }
  50% {
    filter: brightness(2);
  }
}

.outcome-icon {
  color: rgba(255, 255, 255, 0.70);
  width: 30px;
  height: 40px;
  margin: 10px;
}