
@import '../../components/glassTheme/Common.scss';

.match-card {
  width: 90%;
  max-width: 1080px;
  height: 100px;
}

.match {
  @extend .diffuse;
  text-align:center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
}

.matchControls {
  width: 100%;
  height: 100%;
  text-align:center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 5;
  transition: all 0.5s ease;
}

.matchSeparator {
  @extend .glass;
  height: 75px;
  width: 75px;
  outline: 1px solid rgba(255, 255, 255, 0.52);
  border-radius: 50%;
  box-sizing:border-box;
  text-align:center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  transition: all 0.5s ease;
}

.matchSeparator:hover {
  @extend .dark-glass;
  scale: 1.1;
  outline: 1px solid rgba(0, 0, 0, 0.52);
  transition: all 0.5s ease;
}

.searchError {
  @extend .glass-bubble;
  @extend .raised-glass;
  width: 60%;
  height: 100px;
  box-sizing:border-box;
  text-align:center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  z-index: 10;
}

.searchErrorIcon {
  @extend .glass-element-subtle;
  width: 30px;
  height: 30px;
}

.player {
  height: 100%;
  width: 100%;
  text-align:center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0px;
  position: relative;
}

.player-details {
  width: 100%;
  height: 100%;
  box-sizing:border-box;
  text-align:center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0px;
  position: relative;
}

.favourite-icon {
  color: white;
  width: 20px;
  height: 20px;
  margin: 2px;
}

.outcome-icon {
  color: rgba(255, 255, 255, 0.70);
  width: 30px;
  height: 40px;
  margin: 10px;
}

.outcomeControls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 60px;
}

.player-name {
  @extend .glass-h2;
  font-size: larger;
  padding-bottom: 2px;
}

.player-icon {
  z-index: 10;
  min-width: 30px;
  min-height: 30px;
  margin: 0px 20px 20px 20px;
}
.player-icon-base {
  color: rgba(255, 255, 255, 0.70);
  width: 30px;
  height: 30px;

}
.player-icon-left {
  @extend .player-icon-base;
  rotate: -15deg;
}
.player-icon-right {
  @extend .player-icon-base;
  rotate: 15deg;
}

.matchControlsRead {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.matchControlsWrite {
  @extend .matchControlsRead;
}

.streamDataItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.streamDataItemIcon {
  width: 30px;
  height: 30px;
  padding: 0px 5px;
  fill: white;
}
.streamDataItemArrow {
  width: 20px;
  height: 30px;
  padding: 0px 5px;
  fill: white;
}